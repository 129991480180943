import { Link } from "@mui/material";
import React, { ReactNode } from "react";
import { useHistory } from "react-router-dom";

interface RoutedLinkProps {
  to: string;
  children: ReactNode;
}

export default function RoutedLink({ to, children }: RoutedLinkProps) {
  const history = useHistory();

  return (
    <Link
      onClick={(e) => {
        history.push(to);
      }}
      target="_blank"
      rel="noopener"
    >
      {children}
    </Link>
  );
}
