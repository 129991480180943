import { Link } from "@mui/material";
import React, { ReactNode } from "react";

interface NewTabLinkProps {
  href: string;
  children: ReactNode;
}

export default function NewTabLink({ href, children }: NewTabLinkProps) {
  return (
    <Link href={href} target="_blank" rel="noopener">
      {children}
    </Link>
  );
}
