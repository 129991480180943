export let apiKey: string = "";

export class API {
  private readonly apiKey: string;

  constructor({ apiKey = "" }) {
    this.apiKey = apiKey;
  }

  async get(): Promise<Container[]> {
    return new Promise<Container[]>((resolve, reject) => {
      try {
        resolve(JSON.parse(localStorage.getItem("containers") || "[]"));
      } catch (e) {
        reject(e);
      }
    });
  }

  async save(c: Container[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        localStorage.setItem("containers", JSON.stringify(c));
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }
}

export interface Container {
  id: string;
  name: string;
  description?: string;
  items?: Item[];
  containers?: Container[];

  allowedMetadataFields?: Map<string, string>;
}

export interface Item {
  name: string;
  description?: string;
  quantity: Quantity;

  // Optional
  // metadata?: Map<string, MetadataValue>
}

export interface Quantity {
  value: number;
  unit?: string;
}

/*export interface MetadataValue {
    value: any;
    kind: string;
}*/

export interface listContainersRequest {
  apiKey: string;
  nextToken?: string;
}

export interface listContainersResponse {
  containers?: Container[];
  nextToken?: string;
  version: string;
}

export async function listContainers(
  req: listContainersRequest
): Promise<listContainersResponse> {
  console.log("listContainers", req);
  return new Promise<listContainersResponse>((resolve, reject) => {
    try {
      fetch("/api/list-containers", {
        method: "POST",
        body: JSON.stringify(req),
      })
        .then((response) => response.json())
        .then((data) =>
          resolve({ containers: data.containers, version: data.version })
        );
    } catch (e) {
      reject(e);
    }
  });
}

export interface GetContainerRequest {
  containerId: string;
}

export interface GetContainerResponse {
  container?: Container;
}

export async function getContainer(
  req: GetContainerRequest
): Promise<GetContainerResponse> {
  console.log("getContainer", req);
  return new Promise<GetContainerResponse>((resolve, reject) => {
    try {
      const data = localStorage.getItem(`cicontainer2.0.${req.containerId}`);
      if (!data) {
        throw new Error(`container ${req.containerId} not found`);
      }

      const container = JSON.parse(data);
      setTimeout(() => {
        resolve({
          container,
        });
      }, 1000);
      // TODO: Actual implementation.
    } catch (e) {
      reject(e);
    }
  });
}

export interface SaveContainerRequest {
  apiKey: string;
  path: string;
  container: Container | null; // A null container is a deleted container.
  version: string; // global lock version to prevent updating from clobbering eachother.
}

export interface SaveContainerResponse {}

export async function saveContainer(
  req: SaveContainerRequest
): Promise<SaveContainerResponse> {
  console.log("SaveContainerRequest", req);
  return new Promise<SaveContainerResponse>((resolve, reject) => {
    try {
      fetch("/api/save-container", {
        method: "POST",
        body: JSON.stringify(req),
      })
        .then((response) => response.json())
        .then((data) => resolve(data));
    } catch (e) {
      reject(e);
    }
  });
}
