import * as React from "react";
import Button from "@mui/material/Button";
import {
  Box,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
// @ts-ignore
import { Container, Item } from "../api";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import RestoreIcon from "@mui/icons-material/Restore";

interface EditItemModalProps {
  container: Container;
  itemNum: number;
  onEdit: (arg0: Item | null) => void;
  onCancel: () => void;
}

export default function EditItemModal({
  container,
  itemNum,
  onEdit,
  onCancel,
}: EditItemModalProps) {
  const items = container.items || [];

  if (itemNum > items.length || itemNum < 0) {
    throw new Error(`item #${itemNum} does not exist in container`);
  }

  const newItem = itemNum >= items.length;

  const [item, setItem] = useState<Item>(
    newItem
      ? {
          name: "new item",
          quantity: {
            value: 1,
          },
        }
      : items[itemNum]
  );
  const [itemQuantityValue, setItemQuantityValue] = useState<string>(
    Number(newItem ? 0 : items[itemNum].quantity.value).toString()
  );

  const [deleted, setDeleted] = useState<boolean>(false);

  return (
    <Modal open={itemNum !== -1} onClose={onCancel}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Stack spacing={2}>
          <Stack spacing={2} direction="row" justifyContent="space-between">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {newItem ? "Create Item" : "Edit Item"}
            </Typography>
            {!newItem && (
              <IconButton
                onClick={() => {
                  setDeleted((d) => {
                    return !d;
                  });
                }}
              >
                {deleted ? <RestoreIcon /> : <DeleteIcon />}
              </IconButton>
            )}
          </Stack>
          <TextField
            id="standard-basic"
            autoComplete={"off"}
            label="Name *"
            variant="standard"
            value={item.name}
            disabled={deleted}
            onChange={(v) => {
              setItem((item: Item) => {
                return { ...item, name: v.target.value };
              });
            }}
          />
          <TextField
            id="standard-basic"
            autoComplete={"off"}
            label="Description"
            variant="standard"
            multiline
            minRows={2}
            disabled={deleted}
            value={item.description || ""}
            onChange={(v) => {
              setItem((item: Item) => {
                return { ...item, description: v.target.value };
              });
            }}
          />
          <Stack
            direction={"row"}
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <TextField
              id="standard-basic"
              label="Quantity Value *"
              variant="standard"
              type={"number"}
              value={itemQuantityValue}
              disabled={deleted}
              onChange={(v) => {
                if (Number(v.target.value) < 0) {
                  v.target.value = "0";
                }
                setItemQuantityValue(v.target.value);
              }}
            />
            <Stack
              direction={"row"}
              spacing={2}
              alignItems="center"
              justifyContent="space-around"
            >
              <IconButton
                disabled={deleted}
                onClick={() => {
                  setItemQuantityValue((v: string) => {
                    return (Number(v) + 1).toString();
                  });
                }}
              >
                <AddIcon />
              </IconButton>
              <IconButton
                disabled={deleted}
                onClick={() => {
                  setItemQuantityValue((v: string) => {
                    const num = Number(v) - 1;
                    return (num >= 0 ? num : 0).toString();
                  });
                }}
              >
                <RemoveIcon />
              </IconButton>
            </Stack>
          </Stack>
          <TextField
            id="standard-basic"
            label="Quantity Unit"
            variant="standard"
            disabled={deleted}
            value={item.quantity.unit}
            onChange={(v) => {
              setItem((item: Item) => {
                return {
                  ...item,
                  quantity: {
                    ...item.quantity,
                    unit: v.target.value || undefined,
                  },
                };
              });
            }}
          />
          <Typography variant="caption" display="block" gutterBottom>
            * - required
          </Typography>
          <Button
            fullWidth={true}
            disabled={!item.name}
            variant="contained"
            color={deleted ? "error" : "primary"}
            onClick={() => {
              onEdit(
                deleted
                  ? null
                  : {
                      ...item,
                      quantity: {
                        ...item.quantity,
                        value: Number(itemQuantityValue),
                      },
                    }
              );
            }}
          >
            {deleted ? "Delete" : newItem ? "Create" : "Save"}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
