import {
  AppBar,
  Button,
  Container as MUIContainer,
  Paper,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import RoutedLink from "../components/RoutedLink";

export interface ISetApiKey {
  setApiKey: (apiKey: string) => void;
}

interface IForm {
  email: string;
  code: string;
}

export function SetApiKey({ setApiKey }: ISetApiKey) {
  const [form, setForm] = useState<IForm>({
    email: "",
    code: "",
  });

  return (
    <>
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: "relative",
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            <RoutedLink to={"/"}>Contain It!</RoutedLink>
          </Typography>
        </Toolbar>
      </AppBar>
      <MUIContainer component="main" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Stack spacing={2}>
            <Typography component="h1" variant="h4">
              Login
            </Typography>
            <Typography>
              <i>ContainIt!</i> is currently in closed alpha. Contact Harrison
              to be added to the alpha.
            </Typography>
            <TextField
              id="standard-basic"
              autoComplete={"off"}
              label="Email"
              variant="standard"
              value={form.email}
              onChange={(v) => {
                setForm((form: IForm) => {
                  return {
                    ...form,
                    email: v.target.value,
                  };
                });
              }}
            />
            <TextField
              id="standard-basic"
              autoComplete={"off"}
              label="Code"
              variant="standard"
              value={form.code}
              onChange={(v) => {
                setForm((form: IForm) => {
                  return {
                    ...form,
                    code: v.target.value,
                  };
                });
              }}
            />
            <Button
              fullWidth={true}
              variant="contained"
              color={"primary"}
              onClick={() => {
                setApiKey(`${form.email}:${form.code}`);
              }}
            >
              {"Login"}
            </Button>
          </Stack>
        </Paper>
      </MUIContainer>
    </>
  );
}
