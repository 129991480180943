import * as React from "react";
import {
  Alert,
  Breadcrumbs,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { ReactNode, useEffect, useState } from "react";
// @ts-ignore
import { Container, Item } from "../api";
import pluralize from "pluralize";
import EditIcon from "@mui/icons-material/Edit";
import EditItemModal from "./EditItemModal";
import { remove, totalContainerCount, totalItemCount } from "../util";
import AddIcon from "@mui/icons-material/Add";
import EditContainerModal from "./EditContainerModal";
import RoutedLink from "../components/RoutedLink";
import useStorage from "../hooks/useStorage";

export interface IHome {
  apiKey: string;
}

export default function Home({ apiKey }: IHome) {
  const [containers, loading, error, saveContainer] = useStorage({ apiKey });

  let { ids } = useParams<{ ids: string }>();
  console.log("ids", ids);

  console.log("containers", containers);
  let container: Container = {
    id: "",
    name: "Home",
    containers: containers || [],
  };

  const [editingItem, setEditingItem] = useState<number>(-1);
  const [editingContainerId, setEditingContainerId] = useState<string | null>(
    null
  );

  useEffect(() => {
    setEditingItem(-1);
    setEditingContainerId(null);
  }, [ids]);

  let content;

  switch (true) {
    case loading:
      content = (
        <Stack
          direction={"column"}
          spacing={4}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
          <Typography>Loading...</Typography>
        </Stack>
      );
      break;
    case Boolean(error):
      content = <Alert severity="error">Error: {error?.message}</Alert>;
      break;
    default:
      const breadcrumbLinks: ReactNode[] = [];

      let missingContainer = "";

      if (Boolean(ids)) {
        const parts = ids.split(".");
        main: for (let i = 0; i < parts.length; i++) {
          const part = parts[i];
          console.log(
            "looking in container",
            container,
            "for container with id",
            part
          );
          for (let j = 0; j < (container.containers || []).length; j++) {
            if (!container.containers) {
              console.log("no more containers");
              break;
            }
            if (container.containers[j].id === parts[i]) {
              breadcrumbLinks.push(
                <RoutedLink key={container.id} to={parts.slice(0, i).join(".")}>
                  {container.name}
                </RoutedLink>
              );
              container = container.containers[j];

              console.log("found container", container);
              continue main;
            }
          }
          missingContainer = part;
          break;
        }
      }

      console.log("container", container);

      let containerContent = (
        <ListItem>
          <ListItemText>
            <Typography>No containers</Typography>
          </ListItemText>
        </ListItem>
      );

      if (container.containers && container.containers.length) {
        const listItems: ReactNode[] = [];
        for (let i = 0; i < container.containers.length; i++) {
          const c = container.containers[i];

          const tcc = totalContainerCount(c);
          const tic = totalItemCount(c);

          const summaryItems = [];
          tcc && summaryItems.push(`${tcc} ${pluralize("containers", tcc)}`);
          tic && summaryItems.push(`${tic} ${pluralize("items", tic)}`);

          listItems.push(
            <ListItem
              divider={i + 1 < container.containers.length}
              key={c.id}
              secondaryAction={
                <IconButton
                  aria-label="edit"
                  onClick={() => {
                    setEditingContainerId(ids ? `${ids}.${c.id}` : c.id);
                  }}
                >
                  <EditIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={
                  <>
                    <RoutedLink to={ids ? `${ids}.${c.id}` : c.id}>
                      {c.name}
                    </RoutedLink>
                    {" - "}
                    {summaryItems.length > 0
                      ? summaryItems.length === 1
                        ? summaryItems[0]
                        : summaryItems.join(" & ")
                      : "0 containers or items"}
                  </>
                }
                secondary={c.description ? <i>{c.description}</i> : null}
              />
            </ListItem>
          );
        }
        containerContent = (
          <List sx={{ width: "100%", bgcolor: "background" }}>{listItems}</List>
        );
      }

      let itemsContent = (
        <ListItem>
          <ListItemText>
            <Typography>No items</Typography>
          </ListItemText>
        </ListItem>
      );
      if (container.items && container.items.length) {
        const listItems: ReactNode[] = [];
        for (let i = 0; i < container.items.length; i++) {
          // @ts-ignore
          const item = container.items[i];
          listItems.push(
            <ListItem
              divider={i + 1 < container.items.length}
              key={i}
              secondaryAction={
                <IconButton
                  aria-label="edit"
                  onClick={() => {
                    setEditingItem(i);
                  }}
                >
                  <EditIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={`${item.name} - ${item.quantity.value} ${
                  item.quantity.unit
                    ? pluralize(item.quantity.unit, item.quantity.value)
                    : ""
                }`}
                secondary={item.description ? <i>{item.description}</i> : null}
              />
            </ListItem>
          );
        }
        itemsContent = <List sx={{ width: "100%" }}>{listItems}</List>;
      }

      content = (
        <>
          {missingContainer && (
            <Alert severity="error">
              Failed to load container {missingContainer}
            </Alert>
          )}
          <Breadcrumbs aria-label="breadcrumb">
            {breadcrumbLinks}
            <Typography color="text.primary">{container.name}</Typography>
          </Breadcrumbs>
          {container.description && (
            <Typography variant="subtitle2">
              {container.description || ""}
            </Typography>
          )}
          <Divider />
          <Stack
            direction={"row"}
            spacing={2}
            justifyContent="flex-start"
            alignItems="baseline"
          >
            <Typography component="h1" variant="h4">
              Containers
            </Typography>
            <IconButton
              onClick={() => {
                setEditingContainerId(ids ? `${ids}.` : ".");
              }}
            >
              <AddIcon />
            </IconButton>
          </Stack>
          {containerContent}

          {ids && (
            <>
              <Divider />
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent="flex-start"
                alignItems="baseline"
              >
                <Typography component="h1" variant="h4">
                  Items
                </Typography>
                <IconButton
                  onClick={() => {
                    setEditingItem((container.items || []).length);
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Stack>
              {itemsContent}
            </>
          )}

          {editingContainerId !== null && (
            <EditContainerModal
              containers={containers}
              editingContainerId={editingContainerId}
              onEdit={(c: Container | null) => {
                console.log(
                  `onEditContainer path: "${editingContainerId}"; container:`,
                  c
                );
                setEditingContainerId(null);
                saveContainer({
                  path: editingContainerId,
                  container: c,
                });
              }}
              onCancel={() => {
                setEditingContainerId(null);
              }}
            />
          )}

          {editingItem !== -1 && (
            <EditItemModal
              container={container}
              itemNum={editingItem}
              onEdit={(i: Item | null) => {
                let tItems: Item[] = [];
                if (container.items) {
                  tItems = [...container.items];
                }

                if (!i) {
                  // deleted item
                  if (!tItems) {
                    return;
                  }
                  remove(tItems, editingItem);
                } else if (!tItems) {
                  // no existing items
                  tItems = [i];
                } else {
                  tItems[editingItem] = i;
                }
                setEditingItem(-1);
                saveContainer({
                  path: ids,
                  container: {
                    ...container,
                    items: tItems,
                  },
                });
              }}
              onCancel={() => {
                setEditingItem(-1);
              }}
            />
          )}
        </>
      );
  }

  return (
    <>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Stack spacing={2}>{content}</Stack>
      </Paper>
    </>
  );
}
