import { useState, useEffect } from "react";
import { Container, listContainers, saveContainer } from "../api";

export interface IUseStorage {
  apiKey: string;
}

export interface IUpdateContainer {
  path: string;
  container: Container | null;
}

export default function useStorage({
  apiKey,
}: IUseStorage): [
  Container[],
  boolean,
  Error | null,
  ({ path, container }: IUpdateContainer) => void
] {
  interface IData {
    loading: boolean;
    error: null | Error;
    containers: Container[];
    version: string;
  }

  const [data, setData] = useState<IData>({
    loading: true,
    error: null,
    containers: [],
    version: "",
  });

  const [refresh, setRefresh] = useState<number>(0);

  useEffect(() => {
    setData((data) => {
      return {
        ...data,
        loading: true,
      };
    });
    listContainers({ apiKey }).then(({ containers, nextToken, version }) => {
      console.log("useStorage", containers, nextToken, version);
      setData({
        loading: false,
        error: null,
        containers: containers || [],
        version: version,
      });
    });
  }, [refresh, apiKey]);

  return [
    data.containers,
    data.loading,
    data.error,
    ({ path, container }: IUpdateContainer) => {
      saveContainer({ apiKey, path, container, version: data.version }).then(
        () => {
          setRefresh(new Date().valueOf());
        }
      );
    },
  ];
}
