import * as React from "react";
import { ReactNode } from "react";
import {
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
// @ts-ignore
import pluralize from "pluralize";
import { ItemSearchResult, searchContainerItems } from "../util";
import RoutedLink from "../components/RoutedLink";
import { useQuery } from "../hooks/useQuery";
import useStorage from "../hooks/useStorage";

interface SearchAppProps {
  apiKey: string;
}

export default function SearchApp({ apiKey }: SearchAppProps) {
  const query = useQuery();

  const searchTerm = query.get("t") || "";

  const [containers, loading, error] = useStorage({ apiKey });

  let content;
  switch (true) {
    case loading:
      content = <CircularProgress />;
      break;
    case Boolean(error):
      break;
    default:
      let searchedItems: ItemSearchResult[] = [];
      for (let i = 0; i < containers.length; i++) {
        searchedItems.push(
          ...searchContainerItems({ c: containers[i], term: searchTerm })
        );
      }
      console.log("searchedItems", searchedItems);

      content = (
        <ListItem>
          <ListItemText>
            <Typography>No items</Typography>
          </ListItemText>
        </ListItem>
      );
      if (searchedItems.length > 0) {
        const listItems: ReactNode[] = [];
        for (let i = 0; i < searchedItems.length; i++) {
          // @ts-ignore
          const item = searchedItems[i];
          listItems.push(
            <ListItem divider={i + 1 < searchedItems.length} key={i}>
              <ListItemText
                primary={<Typography>{item.name} - {item.quantity.value} {
                    item.quantity.unit
                        ? pluralize(item.quantity.unit, item.quantity.value)
                        : ""
                } - <RoutedLink to={item.path}>
                  {item.containers.join(" / ")}
                </RoutedLink></Typography>}
                secondary={item.description ? <i>{item.description}</i> : null}
                /*secondary={`${item.quantity.value} ${
                  item.quantity.unit
                    ? pluralize(item.quantity.unit, item.quantity.value)
                    : ""
                }`}*/
              />
            </ListItem>
          );
        }
        content = <List sx={{ width: "100%" }}>{listItems}</List>;
      }
  }

  return (
    <Paper
      variant="outlined"
      sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
    >
      <Stack
        direction={"row"}
        spacing={2}
        justifyContent="flex-start"
        alignItems="baseline"
      >
        <Typography component="h1" variant="h4" align="center">
          {`Items matching "${searchTerm}"`}
        </Typography>
      </Stack>
      {content}
    </Paper>
  );
}
