import { Stack, Typography } from "@mui/material";
import NewTabLink from "../components/NewTabLink";

export function Footer() {
  return (
    <Stack spacing={1}>
      <Typography variant="body2" color="text.secondary" align="center">
        Made with ❤️ by{" "}
        <NewTabLink href="https://harrisonhjones.com">
          Harrison Jones
        </NewTabLink>
        . Copyright {new Date().getFullYear()}.
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        Feature requests or bug reports? Submit them{" "}
        <NewTabLink
          href={
            "https://docs.google.com/spreadsheets/d/1fuCdeDHDRHs-S8zA45ZzdzFDB2nZowa48pyVWzFdtug/edit"
          }
        >
          here
        </NewTabLink>
        .
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        ALPHA SOFTWARE ({process.env.REACT_APP_VERSION}); DO NOT SHARE OR
        DISTRIBUTE.
      </Typography>
    </Stack>
  );
}
