import { InputAdornment, TextField } from "@mui/material";
import { useHistory } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

export default function SearchBox() {
  const history = useHistory();

  return (
    <TextField
      onKeyUp={(ev) => {
        if (ev.key !== "Enter") {
          return;
        }

        history.push(
          // @ts-ignore
          `/search?t=${encodeURIComponent(ev.target.value)}`
        );
      }}
      size="small"
      sx={{ m: 1, width: "25ch" }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
}
