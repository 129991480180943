import * as React from "react";
import {
  AppBar,
  Button,
  Container as MUIContainer,
  createTheme,
  CssBaseline,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@mui/material";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeApp from "./Home";
import SearchBox from "../components/SearchBox";
import SearchApp from "./SearchApp";
import RoutedLink from "../components/RoutedLink";
import useLocalStorage from "../hooks/useLocalStorage";
import { SetApiKey } from "./SetApiKey";

function App() {
  const [apiKey, setApiKey] = useLocalStorage<string>("ciapikey", "");

  // const [, setDataExportImportModalOpen] = useState(false);

  const theme = createTheme();

  let content;
  switch (true) {
    case !Boolean(apiKey):
      content = <SetApiKey setApiKey={setApiKey} />;
      break;
    default:
      content = (
        <>
          <AppBar
            position="absolute"
            color="default"
            elevation={0}
            sx={{
              position: "relative",
              borderBottom: (t) => `1px solid ${t.palette.divider}`,
            }}
          >
            <Toolbar>
              <Typography
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                <RoutedLink to={"/"}>Contain It!</RoutedLink>
              </Typography>
              <SearchBox />
              {/*<Button
                color="primary"
                variant="contained"
                disabled={true}
                size="medium"
                aria-label="export & import data"
                onClick={() => {
                  setDataExportImportModalOpen(true);
                }}
              >
                Data Import / Export
              </Button>*/}
              <Button
                onClick={() => {
                  setApiKey("");
                }}
                variant="contained"
                color={"primary"}
                size="medium"
                sx={{ marginLeft: "8px" }}
              >
                Logout {apiKey && apiKey.split(":")[0]}
              </Button>
            </Toolbar>
          </AppBar>
          <MUIContainer component="main" sx={{ mb: 4 }}>
            <Switch>
              <Route path="/search">
                <SearchApp apiKey={apiKey} />
              </Route>
              <Route path="/:ids">
                <HomeApp apiKey={apiKey} />
              </Route>
              <Route path="/" exact={true}>
                <HomeApp apiKey={apiKey} />
              </Route>
            </Switch>
          </MUIContainer>
        </>
      );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>{content}</Router>
    </ThemeProvider>
  );
}

export default App;
