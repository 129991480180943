import * as React from "react";
import Button from "@mui/material/Button";
import {
  Box,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
// @ts-ignore
import { Container } from "../api";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreIcon from "@mui/icons-material/Restore";

interface EditContainerModalProps {
  containers: Container[];
  editingContainerId: string;
  onEdit: (container: Container | null) => void;
  onCancel: () => void;
}

export default function EditContainerModal({
  containers,
  editingContainerId,
  onEdit,
  onCancel,
}: EditContainerModalProps) {
  const [deleted, setDeleted] = useState<boolean>(false);

  // editingContainerId looks like "rootId.subId1.subId2..." OR just "" if it's a new container.

  const newContainer = editingContainerId.endsWith(".");

  let initialContainer: Container = {
    id: "id", // doesn't matter.
    name:
      editingContainerId === "."
        ? "root container name"
        : "non-root container name",
  };

  if (!newContainer) {
    const ids = editingContainerId.split(".");

    let tempC: Container | undefined = {
      id: "temp",
      name: "temp",
      containers: containers,
    };
    console.log(`traversing containers in search for ${editingContainerId}`);
    for (let i = 0; i < ids.length; i++) {
      console.log("looking at container", tempC);
      tempC = tempC.containers?.find((container) => {
        return container.id === ids[i];
      });
      if (!tempC) {
        throw new Error(`container ${editingContainerId} does not exist`);
      }
    }

    initialContainer = tempC;
  }

  const [container, setContainer] = useState<Container>(initialContainer);

  console.log("EditContainerModal container", container);

  return (
    <Modal open={true} onClose={onCancel}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Stack spacing={2}>
          <Stack spacing={2} direction="row" justifyContent="space-between">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {newContainer ? "Create Container" : "Edit Container"}
            </Typography>
            {!newContainer && (
              <IconButton
                onClick={() => {
                  setDeleted((d) => {
                    return !d;
                  });
                }}
              >
                {deleted ? <RestoreIcon /> : <DeleteIcon />}
              </IconButton>
            )}
          </Stack>
          <TextField
            id="standard-basic"
            autoComplete={"off"}
            label="Name *"
            variant="standard"
            disabled={deleted}
            value={container.name}
            onChange={(v) => {
              setContainer((container: Container) => {
                return { ...container, name: v.target.value };
              });
            }}
          />
          <TextField
            id="standard-basic"
            autoComplete={"off"}
            label="Description"
            variant="standard"
            multiline
            minRows={2}
            disabled={deleted}
            value={container.description || ""}
            onChange={(v) => {
              setContainer((container: Container) => {
                return {
                  ...container,
                  description: v.target.value || undefined,
                };
              });
            }}
          />
          <Typography variant="caption" display="block" gutterBottom>
            * - required
          </Typography>
          <Button
            fullWidth={true}
            disabled={!container.name}
            variant="contained"
            color={deleted ? "error" : "primary"}
            onClick={() => {
              onEdit(deleted ? null : container);
            }}
          >
            {deleted ? "Delete" : newContainer ? "Create" : "Save"}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
